import { useEffect, useState } from 'react'

const RECAPTCHA_KEY = "6LcMBfUpAAAAAEsKrBsFjg-Eis8AvHTRvulW9XjU"
const LOCALHOST_RECAPTCHA_KEY = "6LedtW0qAAAAACawAex9e7OXm4EdIj_oe_ysD2ok"
export const RECAPTCHA_TOKEN = "Hessez_dot_com_ReCaptcha_Token"

declare global {
	interface Window {
		grecaptcha: any;
	}
}

const showBadge = () => {
	if (!window.grecaptcha) return
	window.grecaptcha.ready(() => {
		const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
		if (!badge) return
		badge.style.display = 'block'
		badge.style.zIndex = '1'
	})
}

const hideBadge = () => {
	if (!window.grecaptcha) return
	window.grecaptcha.ready(() => {
		const badge = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement
		if (!badge) return
		badge.style.display = 'none'
	})
}

export const useReCaptcha = (): { reCaptchaLoaded: boolean; generateReCaptchaToken: (action: string) => Promise<string> } => {
	const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false)

	// Load ReCaptcha script
	useEffect(() => {
		if (typeof window === 'undefined' || reCaptchaLoaded) return
		if (window.grecaptcha) {
			showBadge()
			setReCaptchaLoaded(true)
			return
		}
		const script = document.createElement('script')
		script.async = true
		script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
		script.addEventListener('load', () => {
			setReCaptchaLoaded(true)
			showBadge()
		})
		document.body.appendChild(script)
	}, [reCaptchaLoaded])

	// Hide badge when unmount
	useEffect(() => hideBadge, [])

	// Get token
	const generateReCaptchaToken = (action: string): Promise<string> => {
		return new Promise((resolve, reject) => {
			if (!reCaptchaLoaded) return reject(new Error('ReCaptcha not loaded'))
			if (typeof window === 'undefined' || !window.grecaptcha) {
				setReCaptchaLoaded(false)
				return reject(new Error('ReCaptcha not loaded'))
			}
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(RECAPTCHA_KEY, { action }).then((token: string) => {
					localStorage.setItem(RECAPTCHA_TOKEN, token)
					resolve(token)
				})
			})
		})
	}

	return { reCaptchaLoaded, generateReCaptchaToken }
}