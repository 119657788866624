import React, { useState, useRef } from "react";
import { Key } from "@react-types/shared"
import { Button, CheckboxGroup, Checkbox, Input, Textarea, tv, Tabs, Tab, Spinner } from "@nextui-org/react";
import { useFormDataContext } from "../FormDataContext"
import { serviceOptions, frequencyOptions, referralSourceOptions, propertyTypeOptions } from "../data"
import MultiSelect from "./inputs/MultiSelect";
import DropDown from "./inputs/DropDown";
import { RightArrowIcon, LeftArrowIcon } from "./icons";
import { useReCaptcha, RECAPTCHA_TOKEN } from "../hooks/useReCaptcha"
import axios from "axios";
import { getBaseUrl } from "../http";

const checkboxgroupStyles = tv({
	slots: {
		wrapper: "grid xs:grid-cols-2 md:grid-cols-4 gap-1 w-full",
	}
})

function ContactForm() {
	const [selectedTab, setSelectedTab] = useState<Key>("jobDeetz")
	const formRef = useRef<HTMLFormElement>(null);
	const [loading, setLoading] = useState(false)
	const [sendSuccessful, setSendSuccessful] = useState(false)
	const [sendFail, setSendFailed] = useState(false)
	const {reCaptchaLoaded, generateReCaptchaToken} = useReCaptcha()

	const {
		services,
		jobDetails,
		frequency,
		frequencyOther,
		howSoon,
		propertyType,
		address,
		firstName,
		lastName,
		email,
		phone,
		referralSource,
		referralSourceOther,
		subscribe,
		otherDetails,
	} = useFormDataContext();

	let styles: React.CSSProperties = {
		width: "95%",
		margin: "50px auto",
		maxWidth: "840px",
		background: "white",
		borderRadius: "25px",
		boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
	}

	const onSwitchTab = (tab: Key) => {
		const isFormValid = formRef.current?.checkValidity() ?? true;

		if (isFormValid) {
			setSelectedTab(tab)
		}
	}

	const onNextClicked = () => {
		onSwitchTab("contactInfo")
	}

	const onBackClicked = () => {
		setSelectedTab("jobDeetz")
	}

	const onFailBackClicked = () => {
		setSendFailed(false)
		setSelectedTab("contactInfo")
	}

	const submitForm = async () => {
		setLoading(true)
		await generateReCaptchaToken('submit')

		const request = {
			services: services,
			jobDetails: jobDetails,
			frequency: frequency,
			frequencyOther: frequencyOther,
			howSoon: howSoon,
			propertyType: propertyType?.toString() ?? "Something went wrong",
			address: address,
			firstName: firstName,
			lastName: lastName,
			email: email,
			phone: phone,
			referralSource: referralSource,
			referralSourceOther: referralSourceOther,
			subscribe: subscribe,
			otherDetails: otherDetails,
			captchaToken: localStorage.getItem(RECAPTCHA_TOKEN)
		}
		const headers = {
			"Content-type": "application/json"
		}
		axios.post(getBaseUrl() + "/newlead", request, {
			headers: headers
		}).then(_ => {
			setSendSuccessful(true)
		}).catch(err =>  {
			setSendFailed(true)
			console.error(err)
		}).finally(() => {
			setLoading(false)
		})
	}

	const getPhoneScramble = () => {
		return atob('KDIwOCkgNDE5LTkyMzU=')
	}

	const getPhoneLinkScramble = () => {
		return atob('dGVsOisxMjA4NDE5OTIzNQ==')
	}

	const getEmailScramble = () => {
		return atob('aGVzc2V6Y2xlYW5pbmdAZ21haWwuY29t')
	}

	const getMailLink = () => {
		return atob('bWFpbHRvOmhlc3NlemNsZWFuaW5nQGdtYWlsLmNvbQ==')
	}

	var body
	if (loading) {
		body = (
			<div className="flex flex-col py-10">
				<Spinner color="primary" label="Give us a moment while we send that request for you." size="lg" />
			</div>
		)
	} else if (sendSuccessful) {
		body = (
			<div className="flex flex-col items-center gap-3 py-10">
				<p>Thank you for requesting an estimate. We will get back to you with an estimate as soon as we can.</p>
				<p>We look forward to doing business with you!</p>
			</div>
		)
	} else if (sendFail) {
		body = (
			<>
				<div className="flex flex-col items-center gap-3 py-10">
					<h3>Uh oh... something went wrong.</h3>
					<p>We appologize for the inconventience, but we were not able to send your estimate request.</p>
					<p>Please send us an email or give us a call.</p>
					<a href={getPhoneLinkScramble()}>{getPhoneScramble()}</a>
					<a href={getMailLink()}>{getEmailScramble()}</a>
				</div>
				<div className="flex gap-2 justify-start">
					<Button onPress={onFailBackClicked} className="mt-6" color="primary" startContent={<LeftArrowIcon />}>Back</Button>
				</div>
			</>
		)
	} else {
		body = (
			<form onSubmit={submitForm} ref={formRef}>
				<Tabs
					color="secondary"
					variant="underlined"
					fullWidth
					selectedKey={selectedTab}
					onSelectionChange={onSwitchTab}>
					<Tab key="jobDeetz" title="1. Job Details">
						<JobDetails onNextClicked={onNextClicked} />
					</Tab>
					<Tab key="contactInfo" title="2. Contact Info">
						<ContactInfo onBackClicked={onBackClicked} reCaptchaLoaded={reCaptchaLoaded} />
					</Tab>
				</Tabs>
			</form>
		)
	}

	return (
		<div style={styles} className="px-4 sm:px-8 py-5">
			{body}
		</div>
	);
}



function JobDetails(props: { onNextClicked: () => void }) {
	const { onNextClicked } = props

	const {
		services, setServices,
		jobDetails, setJobDetails,
		frequency, setFrequency,
		frequencyOther, setFrequencyOther,
		howSoon, setHowSoon,
		propertyType, setPropertyType,
		address, setAddress,
	} = useFormDataContext();

	let isOtherSelection = (selection: string | undefined) => {
		return selection === 'other'
	}

	const cbStyles = checkboxgroupStyles()

	return (
		<>
			<h2 className="mb-6">Job Details</h2>
			<CheckboxGroup
				value={services}
				onValueChange={setServices}
				isRequired
				label="What services are you looking for?"
				errorMessage="Please select a service or select other."
				classNames={{
					wrapper: cbStyles.wrapper(),
				}}
			>
				{serviceOptions.map(service => <MultiSelect key={service.value} value={service.value}>{service.label}</MultiSelect>)}
			</CheckboxGroup>

			<div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 mt-4 sm:mt-8">
				<div>
					<p className="mb-2">How often do you need this service?</p>
					<DropDown
						selectedItem={frequency}
						setSelectedItem={setFrequency}
						items={frequencyOptions}
						defaultItem={"once"}
						className="pb-2" />
					{isOtherSelection(frequency) && <Input
						value={frequencyOther}
						onValueChange={setFrequencyOther}
						type="text"
						placeholder="How often?"
						color="secondary" />}
				</div>

				<div>
					<p className="mb-2">How soon do you need this service?</p>
					<Input
						value={howSoon}
						onValueChange={setHowSoon}
						type="text"
						placeholder="How soon?"
						color="secondary" />
				</div>
			</div>

			<div className="flex flex-col sm:flex-row gap-x-8 gap-y-4 mt-4 sm:mt-8">
				<div>
					<p className="mb-2">What type of property is this?</p>
					<Tabs
						selectedKey={propertyType}
						onSelectionChange={setPropertyType}
						color="secondary"
						variant="bordered" >
						{propertyTypeOptions.map(item => (
							<Tab key={item.value} value={item.label} title={item.label} >
							</Tab>
						))}
					</Tabs>
				</div>
				<div className="flex-grow">
					<p className="mb-2">What is the service address?</p>
					<Textarea
						value={address}
						onValueChange={setAddress}
						minRows={1}
						maxRows={3}
						maxLength={200}
						type="address"
						label="Address"
						isRequired
						errorMessage="Please enter a service address"
						labelPlacement="inside"
						color="secondary" />
				</div>
			</div>

			<p className="mt-4 sm:mt-8 mb-2">What else can you tell us about this job?</p>
			<Textarea
				value={jobDetails}
				onValueChange={setJobDetails}
				minRows={4}
				maxRows={12}
				maxLength={10000}
				variant="flat"
				placeholder="Details..."
				description="Please provide as many relevant details as you can such as number of windows to be washed, how many stories the property has, approximate square footage of the property or area being cleaned, whether outdoor furniture or equipment will need to be moved, and whether there is a water source on the property (if requesting powerwashing)."
				color="secondary" />

			<div className="flex justify-end">
				<Button onPress={onNextClicked} className="mt-6" color="primary" endContent={<RightArrowIcon />}>Next</Button>
			</div>
		</>
	)
}

function ContactInfo(props: {
	onBackClicked: () => void,
	reCaptchaLoaded: boolean,
}) {
	const { onBackClicked, reCaptchaLoaded } = props

	const {
		firstName, setFirstName,
		lastName, setLastName,
		email, setEmail,
		phone, setPhone,
		referralSource, setReferralSource,
		referralSourceOther, setReferralSourceOther,
		subscribe, setSubscribe,
		otherDetails, setOtherDetails,
	} = useFormDataContext();

	let isOtherSelection = (selection: string | undefined) => {
		return selection === 'other'
	}

	return (
		<>
			<h2 className="mb-6">Contact Information</h2>
			<div className="grid sm:grid-cols-2 gap-x-4 sm:mb-2">
				<Input
					value={firstName}
					onValueChange={setFirstName}
					type="name"
					label="First Name"
					isRequired
					errorMessage="Please enter your first name."
					labelPlacement="inside"
					color="secondary" />

				<Input
					value={lastName}
					onValueChange={setLastName}
					type="name"
					label="Last Name"
					isRequired
					errorMessage="Please enter your last name."
					
					labelPlacement="inside"
					color="secondary" />
			</div>

			<div className="grid sm:grid-cols-2 gap-x-4">
				<Input
					value={email}
					onValueChange={setEmail}
					type="email"
					label="Email"
					isRequired
					errorMessage="Please enter a valid email."
					labelPlacement="inside"
					color="secondary" />

				<Input
					value={phone}
					onValueChange={setPhone}
					type="tel"
					label="Phone"
					labelPlacement="inside"
					color="secondary" />
			</div>

			<p className="mt-6 mb-4">How did you hear about us?</p>
			<DropDown
				selectedItem={referralSource}
				setSelectedItem={setReferralSource}
				items={referralSourceOptions}
				defaultItem={"once"}
				className="pb-2" />

			{isOtherSelection(referralSource) && <Input
				value={referralSourceOther}
				onValueChange={setReferralSourceOther}
				type="text"
				placeholder="How did you hear about us?"
				className="pb-2"
				color="secondary" />}

			<p className="mt-6 mb-4">Is there anything else we should know?</p>
			<Textarea
				placeholder="Feel free to jot down anything else you think we should know."
				value={otherDetails}
				onValueChange={setOtherDetails}
				minRows={4}
				maxRows={12}
				variant="flat"
				color="secondary" />

			<Checkbox className="mt-6" isSelected={subscribe} onValueChange={setSubscribe} color="primary">
				Is it okay if we send occassional promotional emails?
			</Checkbox>

			<div className="flex gap-2 justify-end">
				<Button onPress={onBackClicked} className="mt-6" color="primary" startContent={<LeftArrowIcon />}>Back</Button>
				<Button type="submit" isLoading={!reCaptchaLoaded} className="mt-6 g-recaptcha" color="primary" endContent={<RightArrowIcon />}>Send Estimate Request</Button>
			</div>
		</>
	)
}

export default ContactForm;