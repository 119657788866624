import React from "react";

interface IconProps {
	color?: string,
	size?: string,
	height?: string,
	width?: string,
}

function LeftArrowIcon(props: IconProps) {
	const { color, size, height, width } = props

	return (
		<svg width={size || width || 24} height={size || height || 24} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path d="M13.1587 8.15375L4.8124 16.5L13.1587 24.8462" stroke={color || 'currentColor'} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4.8125 16.5H27.9537" stroke={color || 'currentColor'} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</svg>

	)
}

export default LeftArrowIcon;